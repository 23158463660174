<template>
    <div class="dashboard_alert">
        <div class="dashboard_alert__area" v-if="state.show_alert">
            <a class="dashboard_alert__area__close_button" @click="closeGuide"></a>
            <div class="dashboard_alert__area__title">
                <p>{{ props.title || ""}}</p>
                <p class="title-show">{{ props.title || ""}}</p>
            </div>
            <ol v-if="props.list">
                <li v-for="message of props.list" :key="message">{{message}}</li>
            </ol>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/components/dashboard-alert/DashboardAlert.ts" scoped>
</script>

<style lang="scss" src="@/presentation/components/dashboard-alert/DashboardAlert.scss" scoped>
</style>
