<template>
  <LeftSizePreloader v-if="state.preload" />
  <div v-else class="user_profile">
    <div
      class="user_profile__image"
      :class="{ image: state.userProfileMenuInfo.image }"
    >
      <img
        v-if="state.userProfileMenuInfo.image"
        :src="state.userProfileMenuInfo.image"
        alt=""
      />
      <a target="_blank" :href="state.signateUserProfile" class="user_profile__image_edit shadow" v-if="state.isOwner">写真を変更</a>
    </div>
    <div class="user_profile__information">
      <router-link :to="{path: '/user/profile/' + state.userProfileMenuInfo.account_id}" class="blue-text">{{ state.userProfileMenuInfo.nickname }}</router-link>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/components/user-profile/UserProfile.ts" scoped>
</script>
<style lang="scss" src="@/presentation/components/user-profile/UserProfile.scss" scoped>
</style>
