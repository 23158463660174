import LanguageUtil from "@/commons/LanguageUtil";
import { useStore } from "vuex";
import { defineComponent, reactive, onMounted, } from "vue";
export default defineComponent({
    name: "DashboardAlert",
    props: [
        "show_alert",
        "title",
        "list"
    ],
    setup: function (props) {
        var _a;
        var store = useStore();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            show_alert: (_a = props.show_alert) !== null && _a !== void 0 ? _a : true
        });
        function setReadScreen(screen) {
            localStorage.setItem(screen, "seen");
        }
        function checkReadScreen(screen) {
            return localStorage.getItem(screen) ? true : false;
        }
        function closeGuide() {
            setReadScreen(store.state.screen);
            state.show_alert = false;
        }
        onMounted(function () {
            if (store.state.screen) {
                if (checkReadScreen(store.state.screen)) {
                    state.show_alert = false;
                }
                else {
                    state.show_alert = true;
                }
            }
            store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "checkReadGuideScreen") {
                    if (store.state.screen) {
                        if (checkReadScreen(store.state.screen)) {
                            state.show_alert = false;
                        }
                        else {
                            state.show_alert = true;
                        }
                    }
                }
            });
        });
        return {
            state: state,
            store: store,
            props: props,
            closeGuide: closeGuide
        };
    }
});
