import LanguageUtil from "@/commons/LanguageUtil";
import { useStore } from "vuex";
import { defineComponent, reactive, onMounted, watch } from "vue";
import Star from "@/presentation/components/star-profile/StarProfile.vue";
import UserInteractor from "@/domain/usecases/UserInteractor";
import DIContainer from "@/core/DIContainer";
import LeftSizePreloader from "@/presentation/components/preloader/left-side-preloader/LeftSizePreloader.vue";
import CommonService from "@/services/CommonService";
import MyConfig from '@/_config';
import TransformUtil from "@/commons/TransformUtil";
export default defineComponent({
    name: "UserProfile",
    components: {
        Star: Star,
        LeftSizePreloader: LeftSizePreloader
    },
    props: [
        "profile",
        "rating",
        "guest",
        "accountId"
    ],
    setup: function (props) {
        var store = useStore();
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            userProfileMenuInfo: {},
            isOwner: false,
            preload: true,
            signateUserProfile: MyConfig.signateProfile
        });
        watch(function () { return props.accountId; }, function (newVal, oldVal) {
            getProfile(newVal);
        }, { immediate: true });
        function getProfile(accountId) {
            if (accountId == null) {
                return;
            }
            var user = CommonService.getCookie("user");
            if (user) {
                var user_info = JSON.parse(decodeURIComponent(user));
                state.isOwner = user_info.account_id == accountId;
            }
            userInteractor
                .getUserProfileMenu(props.accountId)
                .then(function (result) {
                state.userProfileMenuInfo = result.data;
                state.userProfileMenuInfo.rating_ave = TransformUtil.pointRound(state.userProfileMenuInfo.rating_ave);
                state.userProfileMenuInfo.rating_count = TransformUtil.formatNumber(state.userProfileMenuInfo.rating_count);
                if (state.isOwner) {
                    store.commit("setUserMenuInfo", result.data);
                }
                state.preload = false;
            })
                .catch(function (error) {
                state.preload = false;
            });
        }
        onMounted(function () { });
        return {
            state: state,
        };
    }
});
