import LanguageUtil from "@/commons/LanguageUtil";
import { useStore } from "vuex";
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import DIContainer from "@/core/DIContainer";
import SMSPopup from "@/presentation/components/modals/sms/SMS.vue";
import OTPPopup from "@/presentation/components/modals/otp/Otp.vue";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import { defineComponent, reactive, onMounted, onUnmounted } from "vue";
import routePath from "@/commons/RoutePath";
export default defineComponent({
    name: "UserMenu",
    components: {
        SMSPopup: SMSPopup,
        OTPPopup: OTPPopup,
        Alert: Alert
    },
    setup: function () {
        var store = useStore();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            showSMSPopup: false,
            showAlertPopup: false,
            alertContent: {
                title: "SMS認証",
                content: "SMS認証済みです"
            },
            showSMSAuth: false
        });
        var authInteractor = DIContainer.instance.get(AuthInteractor);
        var unsubscribeStore;
        function checkSMS(init) {
            if (init === void 0) { init = false; }
            authInteractor.smsCheck()
                .then(function (result) {
                if (!result.data) {
                    return;
                }
                if (result.data.result == false) {
                    if (init == true) {
                        state.showSMSAuth = true;
                    }
                    else {
                        state.showSMSPopup = true;
                    }
                }
                else if (result.data.result == true) {
                    if (init == true) {
                        state.showSMSAuth = false;
                    }
                }
            })
                .catch(function () {
            });
        }
        onMounted(function () {
            checkSMS(true);
            unsubscribeStore = store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "setHidenMenu") {
                    state.showSMSAuth = false;
                }
            });
        });
        onUnmounted(function () {
            unsubscribeStore();
        });
        return {
            state: state,
            store: store,
            routePath: routePath,
            checkSMS: checkSMS
        };
    }
});
