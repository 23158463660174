import { __awaiter, __generator } from "tslib";
import { reactive, defineComponent, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import Navigation from "@/presentation/components/navigation/Navigation.vue";
import DashboardAlert from "@/presentation/components/dashboard-alert/DashboardAlert.vue";
import UserProfileComponent from "@/presentation/components/user-profile/UserProfile.vue";
import UserMenuComponent from "@/presentation/components/user-menu/UserMenu.vue";
import UserInteractor from "@/domain/usecases/UserInteractor";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import DIContainer from "@/core/DIContainer";
import CommonService from "@/services/CommonService";
import routePath from "@/commons/RoutePath";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import { getCurrentAccountId } from "@/commons/UserStatusUtis";
import RoundInteractor from "@/domain/usecases/RoundInteractor";
export default defineComponent({
    name: "UserProfile",
    components: {
        Breadcrumb: Breadcrumb,
        Navigation: Navigation,
        UserProfileComponent: UserProfileComponent,
        UserMenuComponent: UserMenuComponent,
        DashboardAlert: DashboardAlert,
        Alert: Alert
    },
    setup: function () {
        var _this = this;
        var store = useStore();
        var router = useRouter();
        var route = useRoute();
        var state = reactive({
            preload: true,
            listBreadcrumb: [
                {
                    route: "/user/dashboard",
                    name: "ダッシュボード"
                },
                {
                    route: "/user/profile/" + route.params.userId,
                    name: ""
                }
            ],
            listPerfecture: [],
            currentUserAccountId: Number(route.params.userId),
            isOwner: false,
            showAlertPopup: false,
            alertContent: {
                title: "",
                content: ""
            },
            teams: [],
            round_name: null,
            period_time: null,
            next_period: null
        });
        var roundInteractor = DIContainer.instance.get(RoundInteractor);
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        function checkTeamInputPeriod() {
            return roundInteractor.getRoundTeamInputPeriod().then(function (response) {
                state.round_name = response.round_name;
                state.period_time = response.period_time;
                state.next_period = response.next_period ? response.next_period : { start_at: "未定", end_at: "未定" };
            });
        }
        watch(function () { return route.params; }, function (newParams) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (Number(newParams.userId)) {
                    state.currentUserAccountId = Number(newParams.userId);
                    init();
                }
                return [2 /*return*/];
            });
        }); });
        function init() {
            userInteractor
                .getUserProfile(state.currentUserAccountId)
                .then(function (result) {
                state.preload = false;
                if (!result.data.work_prefecture_name) {
                    result.data.work_prefecture_name = result.data.work_prefecture_name ||
                        (state.listPerfecture.find(function (x) { return x.id === result.data.work_prefecture_id; }) || {}).name;
                }
                store.commit("setUserProfileData", result.data);
                if (CommonService.isOrganization() ||
                    CommonService.isUser() && result.data.account_id !== getCurrentAccountId()) {
                    state.listBreadcrumb[1].name = result.data.nickname;
                }
            })
                .catch(function (error) {
                state.preload = false;
                router.push(routePath.PAGE_404);
            });
            var user = CommonService.getCookie("user");
            var user_infor = {};
            if (user) {
                user_infor = JSON.parse(decodeURIComponent(user));
                state.isOwner = user_infor.account_id == Number(state.currentUserAccountId);
            }
        }
        onMounted(function () {
            var role = CommonService.getCookie("user_roles");
            state.listBreadcrumb[0].route = role == "user" ? "/user/dashboard" : "/organization/dashboard";
            if (CommonService.isUser() &&
                getCurrentAccountId() === parseInt(route.params.userId)) {
                state.listBreadcrumb[1].name = "プロフィール";
            }
            store.commit("setPrefectures", []);
            masterInteractor
                .getAllPerfecture()
                .then(function (result) {
                if (!result.data) {
                    return;
                }
                ;
                state.listPerfecture = result.data;
                store.commit("setPrefectures", result.data);
            })
                .catch(function (error) {
            });
            store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "setUserProfileId") {
                    state.preload = true;
                    state.currentUserAccountId = stateVuex.userProfileId;
                    init();
                }
            });
            checkTeamInputPeriod();
            init();
        });
        return {
            route: route,
            routePath: routePath,
            state: state
        };
    }
});
