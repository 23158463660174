<template>
    <div class="user_menu">
        <router-link class="user_menu_team-list" :to="routePath.TEAMS">チーム管理</router-link>
        <router-link class="user_menu_message" :to="routePath.MESSAGE">メッセージ一覧</router-link>
        <a class="user_menu_check_sms" href="javascript:void(0);" v-if="state.showSMSAuth" @click="checkSMS()">SMS認証</a>
        <hr />
        <!-- <router-link :to="routePath.HELP">使い方ガイド</router-link> -->
        <router-link class="user_menu_contact" :to="routePath.CONTACT">お問い合わせ</router-link>
        <SMSPopup
            v-if="state.showSMSPopup == true"
            @close="state.showSMSPopup = false"
            @openOtp="state.showOtpPopup = true"
        />
        <OTPPopup v-if="state.showOtpPopup == true" @close="state.showOtpPopup = false" @success="state.showSMSAuth = false"/>
        <Alert
            v-if="state.showAlertPopup == true"
            :title="state.alertContent.title"
            :content="state.alertContent.content"
            @close="closePopup"
        />
  </div>
</template>

<script lang="ts" src="@/presentation/components/user-menu/UserMenu.ts" scoped>
</script>

<style lang="scss" src="@/presentation/components/user-menu/UserMenu.scss" scoped>
</style>
