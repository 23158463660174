<template>
    <div class="profile">
        <Navigation />
        <Breadcrumb :breadcrumb="state.listBreadcrumb" />
        <!-- お知らせ -->
        <div v-if="state.next_period" class="user_profile__info">
          <div class="information_area">
            <div class="information_area__block">
              <div class="information_area__block__news">
                <span class="label_top">
                  お知らせ
                </span>
              </div>
              <div class="information_area__block__contents">
                <div v-if="state.round_name && state.period_time?.start_at && state.period_time?.end_at" class="information_area__block__contents__main__round">
                  <div class="information_area__block__contents__main__round__set">
                    <span class="information_area__block__contents__main__round__set__text">
                      現在{{ state.round_name }}のチーム情報変更期間です。
                      期間内にチーム情報の登録、編集を行うようにしてください。
                    </span><br>
                    <span class="time_limit">
                      チーム情報変更期間:{{ state.period_time?.start_at }} 〜 {{ state.period_time?.end_at }}
                    </span>
                  </div>
                </div>
                <div v-else class="information_area__block__contents__main__round">
                  <div class="information_area__block__contents__main__round__set">
                    <span class="information_area__block__contents__main__round__set__text">
                    現在はチーム情報変更期間外です。
                    チーム情報の登録、編集は行えません。
                    </span><br>
                    <span v-if="state.next_period?.start_at === '未定'" class="time_limit">
                      次回のチーム情報変更期間は未定です。
                    </span>
                    <span v-else class="time_limit">
                      次回のチーム情報変更期間:{{ state.next_period?.start_at }} 〜 {{ state.next_period?.end_at}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="profile__container container">
            <div class="profile__container__left left-side">
                <UserProfileComponent :accountId="state.currentUserAccountId"/>
            </div>
            <div class="profile__container__right main-area">
                <div class="profile__container__right__tab">
                    <router-link
                        :to="{ path: '/user/profile/' + state.currentUserAccountId + '/info' }"
                        :class="{ 'active': route.name == 'UserProfileInfo' }"
                        >基本情報</router-link
                    >
                    <router-link
                        v-if="state.isOwner"
                        :to="{ path: '/user/profile/' + state.currentUserAccountId + '/team' }"
                        :class="{ 'active': route.name === 'UserProfileTeam' }"
                        >チーム</router-link
                    >
                    <router-link
                        :to="{ path: '/user/profile/' + state.currentUserAccountId + '/skill' }"
                        >スキル</router-link
                    >
                    <router-link
                        :to="{ path: '/user/profile/' + state.currentUserAccountId + '/activity' }"
                        >アクティビティ</router-link
                    >
                    <router-link
                        v-if="false"
                        :to="{ path: '/user/profile/' + state.currentUserAccountId + '/service' }"
                        >販売サービス</router-link
                    >
                </div>
                <router-view
                    :alert="state.alert"
                    :breadcrumb="state.listBreadcrumb"
                    :preload="state.preload" />
            </div>
        </div>
    </div>
    <Alert
      v-if="state.showAlertPopup == true"
      :title="state.alertContent.title"
      :content="state.alertContent.content"
      @close="closePopup"
    />
</template>
<script lang="ts" src="@/presentation/views/user/profile/Profile.ts" />
<link type="scss" src="@/presentation/views/user/profile/Profile.scss" />
